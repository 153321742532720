<!-- cms fieldset: http://lernwege-cms.novu.io/cp/fields/fieldsets/bard/edit -->

<template>
  <div ref="element" class="style-bard" v-html="html" />
</template>

<script setup lang="ts">
const element = ref<HTMLDivElement>()

defineProps<{
  html?: string | null
}>()

useNuxtLinks(element)
</script>
